var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "background-color": "#b0d9ff"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginUser.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    staticClass: "main-row"
  }, [_c('v-col', {
    staticClass: "login-col"
  }, [_c('div', {
    staticClass: "language-row"
  }, [_c('LanguageDropDown')], 1), _c('v-col', {
    staticClass: "form-col"
  }, [_c('div', {
    staticClass: "header-row"
  }, [_c('div', {
    staticClass: "login-decoration-row"
  }), _c('v-row', {
    staticClass: "title-row"
  }, [_c('h1', {
    staticClass: "login-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Login")) + " ")])]), _c('BaseInput', {
    attrs: {
      "id": "email-input",
      "auto-focus": _vm.isSafari,
      "auto-complete": 'username',
      "placeholder": "E-mail",
      "icon-left": "email.svg"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      "id": "password-input",
      "auto-complete": 'current-password',
      "auto-focus": _vm.isSafari,
      "placeholder": _vm.$t('Password'),
      "type": "password",
      "icon-left": "lock.svg"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-row', {
    staticClass: "login-row"
  }, [_c('button', {
    staticClass: "login-button content-semibold",
    attrs: {
      "name": "login-button",
      "type": "submit"
    },
    on: {
      "click": _vm.loginUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Login")) + " ")])]), _vm.responseMessage ? _c('v-row', {
    staticClass: "error-row"
  }, [_c('p', [_vm._v(_vm._s(_vm.responseMessage))])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "footer-row"
  }, [_c('a', {
    attrs: {
      "href": "https://www.runproperty.com/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/rpFullLogo.svg"),
      "alt": ""
    }
  })]), _c('p', {
    staticStyle: {
      "opacity": "0.6",
      "font-size": "12px"
    }
  }, [_vm._v(" ver. " + _vm._s(_vm.formatVersion(_vm.buildVersion)) + " ")])])], 1), _c('v-col', {
    class: {
      'image-col': !_vm.isUusMaa,
      'image-col-uusmaa': _vm.isUusMaa,
      'image-col-kaanon': _vm.isKaanon,
      'image-col-domus': _vm.isDomus
    }
  }, [_vm.isUusMaa || _vm.isKaanon || _vm.isDomus ? _c('img', {
    staticClass: "company-logo",
    attrs: {
      "src": _vm.companyLogoUrl,
      "alt": "Company Logo"
    }
  }) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }